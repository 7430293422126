.controller{
    position:fixed;
    width: 26px;
    height: 14px;
    z-index: 100;
    bottom: 44px;
    left: 50%;
    margin-left: -12px;
    -webkit-animation: bounce-down 1.6s linear infinite;
    animation: bounce-down 1.6s linear infinite;
    .img{
        position: absolute;
    }
}


@-webkit-keyframes bounce-down {
    25% {
         -webkit-transform: translateY(-4px);
    }
    50%, 100% {
         -webkit-transform: translateY(0);
    }
    75% {
         -webkit-transform: translateY(4px);
    }
}

@keyframes bounce-down {
     25% {
          transform: translateY(-4px);
     }
     50%, 100% {
          transform: translateY(0);
     }
     75% {
          transform: translateY(4px);
     }
}
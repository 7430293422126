.wrapper{
    font-size: 12px;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.75);
    z-index: 998;
    position: fixed;
    .content{
        position: absolute;
        top: 120px;
        left: calc(50% - 400px);
        width: 800px;
        z-index: 999;
        background-color: #fff;
        box-sizing: border-box;
        .close_btn{
            height: 40px;
            text-align: right;
            .btn{
                cursor: pointer;
                display: inline-block;
                width: 40px;
                height: 40px;
                line-height: 36px;
                text-align: center;
                font-size: 36px;
                color: #fff;
                background-color: #C8B88E;
            }
        }
        .cont_text{
            padding: 0 40px 80px 40px;
            .h5{
                margin-top: 40px;
                font-weight: 400;
                font-size: 40px;
                line-height: 48px;
                color: #18181B;
                padding-bottom: 24px;
                position: relative;
                &::after{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 80px;
                    height: 2px;
                    background: #C8B88E;
                    content: "";
                }
            }
            .info_box{
                .info{
                    margin-top: 24px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: #18181B;
                }
            }
        }
    }
    // 手机端
    @media only screen and (max-width: 600px) {
        .content{
            width: 9.3334rem;
            
            top: 2.1333rem;
            left: calc(50% - 4.6667rem);
            .cont_text{
                padding: 0;
                height:calc(100vh - 4.2667rem);
                overflow: hidden;
                .h5{
                    font-size: 1.0667rem;
                    padding: 0 .5333rem .5333rem .5333rem;
                    &::after{
                        left: .5333rem;
                    }
                }
                .info_box{
                    padding: 0 .5333rem;
                    overflow-y: auto;
                    height: calc(100% - 2.1333rem);
                    margin-top: .5333rem;
                    .info{
                        margin: 0;
                        font-size: .3733rem;
                        margin-bottom: 1.8667rem;
                    }
                }
            }
        }

    }
}
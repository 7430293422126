.pagination_modifer{
    text-align: left !important;
    bottom: 0 !important;
    opacity: 1;
    height: 16px;
    display: flex;
}
.pagination_style{
    width: 16px;
    height: 2px;
    display: inline-block;
    background:#ddd;
    margin:  0 2px;
    cursor: pointer;
    z-index: 10;
    }
.pagination_active{
    /* background: #ffffff; */
    background: #C8B88E;

}


@media only screen and (max-width: 600px) {
    .pagination_modifer{
        opacity: 0;
    }
}
.html_style{
    width: 100%;
}
.html_style img{
    max-width: 100% !important;
    min-width: auto;
}
.html_style p{
    color: #18181B;
}
.html_style .text_p{
    font-weight: 400;
    line-height: 28px;
}
.wrapper {
    font-size: 12px;
    // background-image: url('../../assets/images/background.jpg');
    // background-attachment: fixed;
    // background-size: cover;
    // background-repeat: no-repeat;
    position: relative;
    .bg{
        background-image: url('../../assets/images/Image-Team_Background.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: -1;    
    }
    .banner_box {
        padding-top: 120px;
        padding-left: 40px;
        padding-right: 40px;
        max-width: 1820px;
        margin: 12px auto 56px auto;
        .title {
            font-weight: 400;
            font-size: 40px;
            line-height: 48px;
            color: #FFFFFF;
        }

        .title_en {
            font-weight: 400;
            font-size: 44px;
            line-height: 48px;
            color: #C8B88E;

        }
    }
    .swipper_box{
        padding-bottom:120px;
        padding-left: 40px;
        padding-right: 40px;
        max-width: 1820px;
        margin: 0 auto;
        .swipper_item_box{
            color: #fff;
           .s_head{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;
            margin-top: 44px;
                .s_title{
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 40px;
                    color: #FFFFFF;
                }
                .event_box{
                    padding-right: 40px;
                    display: flex;
                    align-items:center;
                    .prev{
                        cursor: pointer;
                        padding:2px;
                        background-color: #333;
                        .prev_img{
                            vertical-align: middle;
                            transform: rotate(180deg);
                        }
                    }
                    .next{
                        cursor: pointer;
                        margin-left: 16px;
                        padding:2px;
                        background-color: #333;
                        .next_img{
                            vertical-align: middle;
                        }
                    }
                }
           }  
           .s_body{
               .slide_item_box{
                   position: relative;
                   overflow: hidden;
                   height: 100%;
                   width:99.9%;
                   box-sizing: border-box;
                   .user_img_box{
                        width: 100%;
                        box-sizing: border-box;
                       &:hover{
                           cursor: default;
                           .user_img{
                                width: 150%;
                                top: -20%;
                                left: -25%;
                                transition:all 0.5s;
                           }
                           .show{
                                top: 0;
                                background: rgba(24, 24, 27, 0.75);
                                width: 100%;
                                height: 100%;
                                margin: 0;
                                transition: all 0.2s;
                                opacity: 1;
                                .user_info{
                                    display: block;
                                    display: -webkit-box;//对象作为弹性伸缩盒子模型显示 
                                    -webkit-box-orient: vertical;//设置或检索伸缩盒对象的子元素的排列方式 
                                    -webkit-line-clamp: var(--line-clamp);//溢出省略的界限
                                    overflow:hidden; //设置隐藏溢出元素
                                }
                           }
                           .tag_box{
                             display: none;
                           }
                       }
                       .user_img{
                            transition: all 0.5s;
                            width: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    .show{
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 22px;
                        // text-transform: uppercase;
                        color: #FFFFFF;
                        padding: 16px 14px 10px 14px;
                        box-sizing: border-box;
                        width: 100%;
                        opacity: 0;
                        .user_info{
                            width: 100%;
                            transition: all 0.2s;
                            margin-top: 10px;
                            display: none;  
                            line-height: 19px;
                            overflow: hidden;
                        }
                    }
                    .tag_box{
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                   }
                   .info{
                        transition: all 0.3s;
                        margin-top: 6px;
                        font-size: 16px;
                        line-height: 24px;
                        color: #FFFFFF;
                       .name{
                           color: #fff;
                           font-weight: 700;
                           text-shadow: (0px 0px 8px rgba(0, 0, 0, 0.5));
                       }
                       .team_info{
                           color: #C8B88E;
                       }
                   }
               }

           }   
        }
    }
    @media only screen and (max-width: 600px){
        .banner_box{
            padding-left: .5333rem;
            .title{
                font-size: .7467rem;
                line-height: .96rem;
            }
            .title_en{
                font-size: .8533rem;
                line-height: 1.0667rem;
            }

        }
        .swipper_box{
            .swipper_item_box{
                .s_head{
                    padding-left: .5333rem;
                }
            }
        }
    }
}
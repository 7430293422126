.wrapper {
    font-style: normal;
    // text-transform: uppercase;
    font-size: 12px;
    .banner_box {
        background-color: #000;
        background-image: url('../../assets/images/news/banner.jpg');
        background-position: top right;
        background-repeat: no-repeat;
        background-size: contain;
        height: 400px;
        padding-top: 152px;
        box-sizing: border-box;
        .banner_cont {
            width: 1200px;
            margin: 0 auto;
            .title {
                font-weight: 400;
                font-size: 44px;
                line-height: 44px;
                color: #FFFFFF;
            }

            .title_en {
                font-weight: 400;
                font-size: 36px;
                line-height: 36px;
                color: #C8B88E;
                margin-top: 16px;
            }
        }
    }

    .list_box {
        width: 1200px;
        margin: 0 auto;
        .list_item {
            display: flex;
            align-items: center;
            padding-bottom: 34px;
            padding-top: 36px;
            border-bottom: 1px solid #E4E4E7;
            cursor: pointer;
            &:last-child{
                border:none;
            }
            
            .left {
                width: 50%;
                padding-right: 50px;
                box-sizing: border-box;
                .date {
                    font-size: 12px;
                    line-height: 12px;
                    color: #999999;
                }
                .h5 {
                    width: 480px;
                    margin-top: 16px;
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 36px;
                    color: #18181B;
                    cursor: pointer;
                }

                .text {
                    margin-top: 16px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: #18181B;
                }
            }

            .right {
                width: 600px;
                height: 256px;
                overflow: hidden;
            }

        }
        .loading_icon{
            -webkit-animation: spin 1.5s linear 1s 5 alternate;
            animation: spin 1.5s linear infinite;
        }
    }
    @media only screen and (min-width:600px) and (max-width:1200px) {
        .banner_box{
            .banner_cont{
                width: 97%;
                margin: 0 auto;
            }
        }
        .list_box{
            width: 98%;
            min-height: calc(100vh - 641px);
            .list_item{
                .left{
                    padding-right:28px;
                    .h5{
                        font-size: 22px;
                        line-height: 28px;
                        width: 100%;
                    }
                    .text {
                        margin-top: 11px;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 22px;
                    }
                }
                .right {
                    width: 50%;
                    height: 256px;
                    overflow: hidden;
                }
            }
        }
    }
    @media only screen and (max-width: 600px){
        .banner_box{
            .banner_cont{
                width: 92%;
            }
        }
        .list_box{
            width: calc(100% - 1.0667rem);
            margin: 0 .5333rem;
            .list_item{
                display: block;
                width: 100%;
                .img_box{
                    width: 100%;
                    height: 3.9467rem;
                    overflow: hidden;
                }
                .phone_info{
                    width: 100%;
                    margin-top: .4267rem;
                    .phone_h5{
                        width: 100%;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 26px;
                        color: #333333;
                        margin-bottom:.53rem
                    }
                    .phone_date{
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 12px;
                        // text-transform: uppercase;
                        color: #999999;
                    }
                }
            }
        }
    }
}
@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
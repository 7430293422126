.nav_bar{
    display: flex;
    justify-content: space-between;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    .logo{
        // width: 174px;
        text-align: center;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
    }
    // 手机端显示
    .phone_icon{
        display: none;
        display: flex;
        align-items: center;
    }
    .nav_box{
        .nav_cont{
            display: flex;
            align-items: center;
            .nav_item{
                padding: 22px 15px;
                cursor: pointer;
                color: #FFFFFF;
                font-weight:400;
                font-size:14px;
                line-height: 20px;
                .languageCheckPc{
                    width:54px;
                    line-height:24px;
                    border:1px solid rgba(255,255,255,0.25);
                    color:#fff;
                    text-align:center;
                }
            }
            .nav_active{
                position: relative;
                &::after{
                    position: absolute;
                    bottom: 16px;
                    left:calc(50% - 14px);
                    width: 28px;
                    height: 2px;
                    background: #C8B88E;
                    content: "";
                }
            }
        }
        .text_color_fff{
            color: #fff;
        }
    }
    @media  only screen and (max-width: 600px) {
        @mixin public—style {
            width: 100%;
            position: absolute;
            top: 1.7rem;
            left: 0;
            background-color: #18181B;
            z-index: 10;
            overflow: hidden;
            transition: all 0.3s;
            .nav_cont{
                display: block;
                .nav_item{
                    font-size: 0.34rem;
                    padding: .5333rem .5333rem;
                    box-sizing: border-box;
                    color: #e5e5e5;
                    position: relative;
                    line-height:.5333rem;
                    &::after{
                        width: 90%;
                        height: 1px;
                        background-color: rgba(255,255,255,0.2);
                        position: absolute;
                        bottom: 0;
                        left: calc(50% - 45%);
                        content: "";
                    }
                    &:last-child{
                        overflow: hidden;
                        &::after{
                            width: 100%;
                            left: 0;
                            background-color: rgba(255,255,255,0);
                        }
                    }
                    .languageCheckPhone{
                        font-size:.32rem;
                        font-weight:400;
                        color:#fff;
                    }
                }

            }
        }
        padding: 0 0.5rem 0 0;
        height: 1.7rem;
        z-index: 3;
        
        .logo{
            margin-left: 0.2rem;
            // width: 4.6rem;
        }
        .nav_box{
            height: 0;
            @include public—style;
        }
        .phone_nav_box{
            height: 8rem;
            @include public—style;
        }
    }
}
.Mask{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.75);
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 2;
}
.banner{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    //pad and min-pc
    @mixin pad-cont-style {
        width: 80%;
    }    
    width: 100%;
    .pc_banner{
        background: url('../../../assets/images/banner.jpg') no-repeat;
        background-size:cover;
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: -1;      
        overflow: hidden;
    }
    .ipad_banner{
        background: url('../../../assets/images/banner.jpg') no-repeat;
        background-size:cover;
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: -1;      
        overflow: hidden;
    }
    .benner_animation_right_start{
        animation-name: tranRightstart;
        animation-duration: 0.6s;
        animation-fill-mode:forwards;
    }
    .benner_animation_right_end{
        animation-name: tranRightend;
        animation-duration: 0.6s;
        animation-fill-mode:forwards;
    }
    .benner_animation_left_start{
        animation-name: tranleftstart;
        animation-duration: 0.6s;
        animation-fill-mode:forwards;
    }
    .benner_animation_left_end{
        animation-name: tranleftend;
        animation-duration: 0.6s;
        animation-fill-mode:forwards;
    }
    .cont{
        width: 100%;
        height: 100vh;
        .title_box{
            margin: 0 auto;
            width:80%;
            max-width: 1200px;
            text-align: right;
            padding-top:calc(50vh - 80px);
            .title{
                font-style: normal;
                font-weight: 400;
                font-size: 44px;
                line-height: 44px;
                color: #FFFFFF;
            }
            .title_en{
                margin-top: 20px;
                font-weight: 400;
                font-size: 44px;
                line-height: 44px;
                text-transform: uppercase;
                color: #C8B88E;
            }
        }
    }
        .info{
            width:80%;
            max-width: 1200px;
            height: 100vh;
            overflow: hidden;
            padding-top:calc(50vh - 110px);
            box-sizing: border-box;
            text-align: right;
            font-size: 0;
            margin: 0 auto;
            .p{
                display: inline-block;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #FFFFFF;
                width: 626px;
                height: 96px;
                text-align: left;
                padding-left: 20px;
                box-sizing: border-box;
            }
            
        }
        .enInfo{
            padding-top: calc(50vh - 180px);
            .p{
                height: 210px;
            }
        }
    //1680px 尺寸台式机
    @media only screen and (min-width:1680px) {
        .info{
            text-align: right;
            .p{
                font-weight: 400;
                font-size: 18px;
                line-height: 33px;
                color: #FFFFFF;
            }
        }
    }
    //中大尺寸台式机
    @media only screen and (min-width:1201px) and (max-width:1560px) {
            .title_box{
                left: 15vw;
            }
    }
    //中尺寸台式机
    @media only screen and (min-width:600px) and (max-width:1200px) {
        .title_box{
            left: 10vw;
        }
    }
    @media only screen and (max-width: 600px) {
        // background-size:100%;
        // background-image: url('../../../assets/images/banner_mobile.jpg');
        // background: none;
        
       
        
        
        .pc_banner{
            background: url('../../../assets/images/banner_mobile.jpg') no-repeat;
            background-size:cover;
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            z-index: -1;      
            overflow: hidden;
        }
        .phone_banner{
            background: url('../../../assets/images/banner_mobile.jpg') no-repeat;
            background-size:cover;
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            z-index: -1;
        }
        .cont{
            width: 100%;
            height: 100vh;
            .title_box{
                left: 0.54rem;
                text-align: left;
                // padding-top: 6.88rem;
                padding-top: calc(50vh - (7.1733rem / 1.68));
                .title{
                    font-size:1.08rem;
                    line-height: 1.28rem;
                    font-weight: 400;
                    font-size: 1.0667rem;
                }
                .title_en{
                    font-weight: 400;
                    font-size: 1.0667rem;
                    line-height: 1.28rem;
                }
            }
            .min_pc_title_box{
                padding-top: calc(50vh - 150px);
            }
        }
        .info{
            width:calc(100% - 40px);
            height: 100vh;
            margin: 0 auto;
            overflow: hidden;
            text-align: left;
            .p{
                width:100%;
                float: left;
                font-weight: 400;
                font-size: .3733rem;
                line-height: 24px;
                // text-transform: uppercase;
                color: #FFFFFF;
                height: auto;
                text-align: left;
            }
            .min_pc_p{
                font-size: 15px;
            } 
           
        }
        .enInfo{
            padding-top: calc(50vh - 6.08rem);
        }
            
    }

    //动画
    @keyframes tranRightstart {
        0%{
            transform:rotate(0deg) scale(1);
        }
        100%{
            transform:rotate(2deg) scale(1.1)
        } 
    }
    @keyframes tranRightend {
        0%{
            transform:rotate(2deg) scale(1.1);
        }
        100%{
            transform:rotate(0deg) scale(1)
        } 
    }
    //动画
    @keyframes tranleftstart {
        0%{
            transform:rotate(0deg) scale(1);
        }
        100%{
            transform:rotate(-2deg) scale(1.1);
        } 
    }
    @keyframes tranleftend {
        0%{
            transform:rotate(-2deg) scale(1.1);
        }
        100%{
            transform:rotate(0deg) scale(1);
        } 
    }

}

@mixin padding_40 {
    padding: 0 40px;
}
@mixin all_li {
    .li{
        // display: flex;
        overflow: hidden;
        margin-bottom: 28px;
        &:last-child{
            padding-bottom: 30px;
        }
        .logo_box{
            float: left;
            width: 81px;
            border: 1px solid #fff;
            height: 81px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .cont_box{
            width: calc(100% - 101px);
            float: left;
            margin-left: 18px;
            box-sizing: border-box;
            font-style: normal;
            .h6{
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 8px;
            }
            .tag_box{
                color:  #c8b88e;
                margin-bottom: 4px;
                font-weight: 400;
                font-size: 10px;
                line-height: 10px;
                .tag_item{
                    margin-left: 6px;
                    font-size: 12px;
                    line-height: 20px;
                    &:first-child{
                        margin-left: 0;
                    }
                }
            }
            .cont_text{
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                // text-transform: uppercase;
                color: #A1A1AA;
                // max-width: 720px;
                 /*滚动条样式*/
                    &::-webkit-scrollbar {
                        width: 4px;    
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        box-shadow: inset 0 0 5px rgba(0,0,0,0.5);
                        background: rgba(255,255,255,0);
                    }
                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 5px rgba(255,255,255,0.02);
                        border-radius: 5px;
                        background: rgba(255,255,255,0);

                    }
                    &:hover{
                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            box-shadow: inset 0 0 5px rgba(0,0,0,0.5);
                            background: rgba(255,255,255,0.2);
                        }
                        &::-webkit-scrollbar-track {
                            box-shadow: inset 0 0 5px rgba(255,255,255,0.02);
                            border-radius: 5px;
                            background: rgba(255,255,255,0.03);
    
                        }
                    }
            }
        }
        @media only screen and (min-width: 1680px) {
            .cont_box{
                .cont_text{
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }

    }
}

.wrapper{
    font-size:12px;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    width: 100%;

    .img_box{
        height: 100vh;
        width:50%;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: top center;
        background-size:cover;
        position: relative;
    }

    .right_box{
        height: 100vh;
        overflow-y:auto;
        width:50%;
        color: #fff;
        background-color: #18181b;
        box-sizing: border-box;
        padding-top:calc((100vh - 600px) / 2);
        scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1);
        scrollbar-width: thin;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            width: 0;    
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba(255,255,255,0);
        }
        &::-webkit-scrollbar-track {
            border-radius: 5px;
            background: rgba(255,255,255,0);

        }
        .mode_box{
            height: 600px;
            max-width: 800px;
            padding: 0 40px;
            margin: 0 auto;
        .info_box{
            // @include padding_40;
            width: 100%;
            .h5{
                font-weight: 400;
                font-size: 28px;
                line-height: 40px;
                position: relative;
                padding-bottom: 12px;
                // letter-spacing: 2px;
                width: 244px;
                &::after{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 80px;
                    height: 2px;
                    background-color: #c8b88e;
                    content: "";
                }
            }
            .info{
                margin-top: 16px;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                width: 397px;
            }
        }
        .projects_box{
            // @include padding_40;
            @include all_li;
            margin-top: 40px;
        }
    }
    }
    .investment_box{
        background-image: url('../../assets/images/investment/investment_bg.jpg');
        background-size: 100%;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        color: #fff;
        .investment_h5{
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 28px;
            text-align: center;
            margin-top: 88px;
        }
       
        .total_performance{
            width: 1050px;
            margin: 109px auto 120px auto;
            overflow: hidden;
            // display: grid;
            // grid: 170px / auto auto auto auto auto;
            // grid-gap: 64px 40px;
            // text-align: center;
            .performance_item{
                width:170px;
                text-align: center;
                float: left;
                margin-right: 20px;
                margin-left: 20px;
                margin-bottom: 64px;
            
            .performance_logo{
                cursor: pointer;
                width: 81px;
                border: 1px solid #fff;
                height: 81px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                position: relative;
                .link_icon{
                    position: absolute;
                    right: 4px;
                    top: 4px;
                    z-index: 1;
                    transition: all .3s;
                }
            }
            .performance_titel_info{
                margin-top: 8px;
                height: 30px;
                .enterprise_name{
                    cursor: pointer;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    color: #FFFFFF;
                }
                .tag_box{
                    cursor: pointer;
                    color:  #c8b88e;
                    margin-top: 8px;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 10px;
                    .tag_item{
                        margin-left: 6px;
                        font-size: 12px;
                        line-height: 14px;
                        &:first-child{
                            margin-left: 0;
                        }
                    }
                }
            }
        }
            @media only screen and (max-width:1050px) {
                width: 840px;
                // width: 808px;
                // grid: auto / auto auto auto auto;
            }
            @media only screen and (max-width:840px) {
                width: 630px;
                // width: 608px;
                // grid: auto / auto auto auto;
            }
        }
    }
    
}
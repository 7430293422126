 @mixin  product{
    .phone_cont{
        .phone_icon_box{
            width: 100%;
            height:2.45rem;
            overflow: hidden;
            .phone_logo_box{
                width: 2.1333rem;
                border: 1px solid #fff;
                height: 2.1333rem;
                display: flex;
                align-items: center;
                justify-content: center;
                float: left;
            }
            .phone_tag{
                float: left;
                font-size: 0;
                margin-top: .5867rem;
                width: calc(100% - 2.2333rem);
                padding-left: .5rem;
                box-sizing: border-box;
                .phone_h6{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 16px;
                    color: #FFFFFF;
                    margin-bottom: .2133rem;
                }
                .tag_item{
                    font-weight: 400;
                    font-size: .32rem;
                    line-height: .32rem;
                    color: #ECD798;    
                    
                }
            }

        }
        .phone_content{
            font-style: normal;
            width: 100%;
            font-weight: 400;
            font-size:0.373rem;
           line-height: .64rem;
            // text-transform: uppercase;
            color: #A1A1AA;
            text-align: left;
            padding-bottom: .5333rem;
            margin-right: .1333rem;
        }

    }
 }
 
 .wrapper{
    background-color: #18181b;
    overflow: hidden;
 .img_box{
            // background-attachment: fixed;
            background-size: 220%;
            height: 48vh;
            width: 100%;
            background-repeat: no-repeat;
            background-position: top center;
            background-size:cover;
            position: relative;
            .phone_h5{
                font-weight: 400;
                font-size: .7467rem;
                line-height: 1.0667rem;
                color: #FFFFFF;
                position: absolute;
                bottom: 1.0667rem;
                left: .5333rem;
            }
        } 
        .right_box{
            height: 52vh;
            padding: 0;
            width: 100%;
            background-color: #18181b;
            box-sizing: border-box;
            .projects_box{
                padding: 0;
                padding-top:.64rem;
                margin-top:0;
                @include product
            }
    }
    .investment_box{
        min-height: 110vh;
        max-height: auto;
        overflow-y: auto;
        width: 100%;
        color: #fff;
        padding-bottom: 0.6rem;
        .investment_bg{
            background-image: url('../../../assets/images/investment/investment_bg.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top center;
            width: 100%;
            min-height:110vh;
            max-height: auto;
            padding-bottom: 0.9rem;
            position: fixed;
            z-index: -1;
        }
        .investment_h5{
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 28px;
            text-align: center;
            padding-top: 88px;
            margin-top: 0;
        }
        .list_box{
            margin-top: 80px;
            margin-bottom: 0;
            padding:0 .52rem ;
            overflow: hidden;
            // display: grid;
            // grid:2.1333rem / auto auto auto;
            // grid-gap: .8rem .8rem;
            .list_item{
                float: left;
                margin-left: .4rem;
                margin-right: .4rem;
                margin-bottom: .8rem;
                width: 2.1333rem;
                border: 1px solid #fff;
                height: 2.1333rem;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                &:last-child{
                    margin-bottom: 0;
                }
                .link_icon{
                    position: absolute;
                    right: 4px;
                    top: 4px;
                    z-index: 1;
                    transition: all .3s;
                }
            }   
        }
    }
    .phone_mask{
        width: 100vw;
        height: 100vh;
        background-color: rgba(255,255,255,0);
        position:fixed;
        top: 0;
        left: 0;
        z-index: 9;
    }
    .phone_detail{
        position: fixed;
        bottom: -100vh;
        left: 0;
        z-index: 10;
        height: 390px;
        background: #18181B;
        width: 100%;
        box-sizing: border-box;
        padding: 23px 20px 0 20px;
        transition: all 0.4s;
        .phone_close{
            position: absolute;
            right:0.5333rem;
            top: .6133rem;
            z-index: 30;
        }
        @include product
    }
}
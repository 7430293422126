.foolter_wrapper{
    background-color: black;
    min-height: 200px;
    max-height: auto;
    font-size: 0;
    .foolter_cont{
        width: 1200px;
        margin: 0 auto;
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        .cont_info{
            width: 50%;
            color: #fff;
            display: flex;
            .box{
                display: inline-block;
                .h5{
                    font-size: 14px;
                    margin-bottom: 10px;
                }
                .p{
                    
                    font-size: 12px;
                }
            }
            .address{
                width: 42%;
            }
            .email{
                width: 28%;
            }
            .bp{
                width: 28%;
            }
        }
        .qr_box{
            width: 110px;
            text-align: center;
            .qr{
                width: 100%;
            }
            .qr_p{
                color: #fff;
                font-size:14px ;
            }
        }
    }
    @media only screen and (min-width:1000px) and (max-width:1200px) {
        padding: 0 10vw;
        .foolter_cont{
            width: 100%;
            .cont_info{
                width: 66%;
                .address{
                    width: 52%;
                }
                .email{
                    width: 23%;
                }
                .bp{
                    width: 23%;
                }
            }
        }
    }
    @media only screen and (min-width:600px) and (max-width:999px) {
        padding: 0 10vw;
        .foolter_cont{
            width: 100%;
            .cont_info{
                width: 76%;
                .address{
                    width: 53%;
                }
                .email{
                    width: 23%;
                }
                .bp{
                    width: 23%;
                }
            }
        }
    }
    @media  only screen  and (max-width: 600px) {
        display: block;
        margin: 0 auto;
        padding: 1.0667rem .5333rem;
        min-height: 5.3333rem;
        max-height: auto;
        .foolter_cont{
            align-items: flex-start;
            padding: 0;
            width: 100%;
            .cont_info{
                width: 60%;
                display: block;
                .box{
                    display: block;
                    width: 100%;
                    margin-top:.5333rem;
                    &:first-child{
                        margin: 0;
                    }
                    .h5{
                        // margin-bottom: 0.1rem;
                        font-weight: 700;
                        font-size: .3733rem;
                        line-height: 14px;
                        // text-transform: uppercase;
                        color: #FFFFFF;
                        margin-bottom: .4267rem;
                    }
                    .p{
                        font-weight: 400;
                        font-size: .3733rem;
                        line-height: .48rem;
                        // text-transform: uppercase;
                        color: #FFFFFF;
                    }
                }
                //小屏幕 pc
                .min_pc_box{
                    margin-top: 14px;
                    .h5{
                        font-size: 14px;
                        margin-bottom: 6px;
                    }
                    .p{
                        font-size: 14px;
                    }
                }
            }
            .qr_box{
                font-size: 0;
                width: 2.8267rem;
                height: 2.8267rem;
                text-align: center;
                .qr{
                    width: 100%;
                }
                .qr_p{
                    color: #fff;
                    font-size:14px ;
                }
            }
        }
    }
}
.copyright{
        border-top:0.5px solid #666;
        background-color: #000;
        color: #fff;
        text-align: center;
        font-size: 12px;
        padding: 12px 0;
}
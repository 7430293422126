.wrapper {
    font-size: 12px;
    font-style: normal;

    .banner_box {
        color: #fff;
        height: 400px;
        background-color: #18181B;
        padding-top: 138px;
        box-sizing: border-box;

        .h5 {
            font-weight: 400;
            font-size: 28px;
            line-height: 36px;
            text-align: center;
            color: #FFFFFF;
            width: 640px;
            margin: 0 auto;
        }

        .date {
            font-weight: 700;
            font-size: 12px;
            line-height: 12px;
            // text-transform: uppercase;
            color: #999999;
            text-align: center;
            margin-top: 40px;
        }
    }

    .cont_box {
        width: 800px;
        margin: 0 auto;
        padding-bottom: 120px;
        .img_box {
            width: 100%;
            max-height: 300px;
            min-height: auto;
            background-color: #fff;
            // border: 1px solid #ddd;
            margin-top: -70px;
            box-sizing: border-box;
            overflow: hidden;
        }

        .preface {
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            color: #71717A;
            margin-top: 32px;
            padding-bottom: 40px;
            border-bottom: 1px solid #E4E4E7;
            width: 100%;
            margin-bottom: 20px;
        }

        // .speech {
        //     margin-top: 72px;

        //     .h6 {
        //         font-weight: 700;
        //         font-size: 28px;
        //         line-height: 32px;
        //         color: #18181B;
        //     }

        //     .info {
        //         margin-top: 32px;
        //         font-weight: 400;
        //         font-size: 14px;
        //         line-height: 32px;
        //         color: #18181B;
        //     }
        // }

        // .source {
        //     font-weight: 400;
        //     margin-top: 64px;

        //     .source_title {
        //         font-size: 24px;
        //         line-height: 28px;
        //         color: #18181B;
        //     }

        //     .source_info {
        //         margin-top: 28px;
        //         font-size: 14px;
        //         line-height: 28px;
        //         color: #18181B;
        //     }

        // }

        // .ul {
        //     .li {
        //         .li_title {
        //             margin-top: 22px;
        //             font-weight: 700;
        //             font-size: 18px;
        //             line-height: 22px;
        //             color: #18181B;
        //         }

        //         .li_info {
        //             margin-top: 22px;
        //             font-weight: 400;
        //             font-size: 14px;
        //             line-height: 28px;
        //             color: #18181B;
        //         }
        //     }
        // }
        // .footer_box{
        //     width: 100%;
        //     margin-top: 14px;
        //     .footer_img{
        //         width: 100%;
        //         height: 520px;
        //         // border: 1px solid #ddd;
        //         overflow: hidden;
        //     }
        //     .footer_span{
        //         display: block;
        //         font-weight: 400;
        //         font-size: 12px;
        //         line-height: 12px;
        //         color: #71717A;
        //         margin-top: 8px;
        //     }
        //     .footer_info{
        //         margin-top: 14px;
        //         font-weight: 400;
        //         font-size: 14px;
        //         line-height: 24px;
        //         color: #18181B;
        //     }
        // }
    }
    @media only screen and (min-width: 600px) and (max-width:800px){
        .cont_box{
            width: calc(100% - 20px);
        }
    }
    @media only screen and (max-width: 600px){
        .banner_box{
            height: 10.4rem;;
            box-sizing: border-box;
            .h5{
                width: calc(100% - 1.0667rem);
                font-size:.589rem;
            }
        }
        .cont_box{
            width: calc(100% - 1.0667rem);
            .img_box{
                // height:4.2rem;
            }
            .preface {
                font-size: 14px;
                line-height: .64rem;
                margin-top: .5333rem;
                margin-bottom: .8rem;
            }
            // .footer_box{
            //     .footer_img{
            //         height:5.8rem;
            //     }
            // }
        }
        
    }

}
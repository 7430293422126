.wrapper {
    $primary_color: #2D5AE6;

    //md-pc
    @mixin md-cont-style {
        width: 1200px;
    }

    //pad and min-pc
    @mixin pad-cont-style {
        width: 80%;
    }

    //phone
    @mixin phone—cont—style {
        // width: 80%;
        width: calc(100% - 1.08rem);
        display: block;
    }

    .Performance_box {
        font-size: 12px;
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        width: 100%;

        .img_box {
            height: 480px;
            width: 50%;
            overflow: hidden;
            background-repeat: no-repeat;
            background-position: top center;
            background-size: cover;
        }

        .right_box {
            height: 480px;
            width: 50%;
            box-sizing: border-box;

            .info_box {
                width: 100%;
                padding-left: 80px;
                box-sizing: border-box;
                .h5 {
                    padding-top: 102px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 40px;
                    line-height: 48px;
                    color: #18181B;
                    position: relative;
                    padding-bottom: 24px;
                    width: 100%;
                    &::after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 80px;
                        height: 2px;
                        background-color: #c8b88e;
                        content: "";
                    }
                }

                .info {
                    margin-top: 16px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    min-width: calc(100% - 80px);
                    max-width: 600px;
                    min-height: auto;
                    max-height: 146px;
                    overflow: hidden;
                    -webkit-line-clamp: 6;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                }

                .btn {
                    color: #fff;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    padding: 8px 20px 8px 16px;
                    border: none;
                    cursor: pointer;
                    margin-top: 40px;
                    background-color: #C8B88E;
                    display: flex;
                    align-items: center;

                    &:after {
                        border: none;
                        background-color: rgba(0, 0, 0, 0.5);
                    }

                    &:hover {
                        border: none;
                    }
                    .arrows{
                        margin-left: 8px;
                    }
                }
            }

            .projects_box {
                margin-top: 40px;
            }
        }
    }

    .news_wrapper {
        background-color: #000;
        background-image: url('../../../assets/images/news/banner.jpg');
        background-position: top right;
        background-repeat: no-repeat;
        background-size: contain;
        height: 400px;
        .news_cont {
            width: 70%;
            max-width: 1200px;
            margin: 0 auto;
            padding-top: 78px;
            margin-bottom: 120px;

            .news_swiper_box {
                position: relative;
                height: 270px;

                .h6 {
                    font-size: 12px;
                    color: #71717A;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 10;
                    width: 50%;
                }

                .new_cont_box {
                    display: flex;

                    .new_cont {
                        width: 47%;
                        padding-top: 24px;
                        cursor: pointer;
                        .new_h5 {
                            font-size: 28px;
                            color: #FFFFFF;
                            font-weight: 400;
                            line-height: 36px;
                            margin-bottom: 8px;
                            cursor: pointer;
                        }

                        .new_p {
                            line-height: 24px;
                            word-break: break-all;
                            font-size: 14px;
                            color: #FFFFFF;
                            font-weight: 300;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 4;
                            overflow: hidden;
                            cursor: pointer;
                        }
                    }

                    .new_img_box {
                        margin-left: 3%;
                        width: 50%;
                        margin-right: 2px;
                        height: 244px;
                        .new_img {
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }


            }
        }

    }

    //中大尺寸台式机
    @media only screen and (min-width:1356px) and (max-width:1560px) {
        .Performance_box{
            .right_box {
                .info_box {
                    padding-left: 5vw;
                        .h5 {
                            padding-top: 98px;
                            font-size: 36px;
                            line-height: 44px;
                        }
                }
            }
        }
        .news_wrapper {
            .news_cont {
                @include md-cont-style;

                .news_swiper_box {
                    height: 250px;

                    .new_cont_box {
                        .new_cont {
                            .new_h5 {
                                font-size: 22px;
                                line-height: 28px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width:1201px) and (max-width:1356px) {
        .Performance_box{
            .right_box {
                .info_box {
                    padding-left: 4vw;
                    padding-right: 2vw;
                        .h5 {
                            padding-top: 98px;
                            font-size: 36px;
                            line-height: 44px;
                        }
                }
            }
        }
        .news_wrapper {
            .news_cont {
                @include md-cont-style;

                .news_swiper_box {
                    height: 250px;

                    .new_cont_box {
                        .new_cont {
                            .new_h5 {
                                font-size: 22px;
                                line-height: 28px;
                            }
                        }
                    }
                }
            }
        }
    }

    //中尺寸台式机
    @media only screen and (min-width:993px) and (max-width:1200px) {
        .Performance_box{
            .right_box {
                .info_box {
                    padding-left: 3vw;
                    padding-right: 2vw;
                        .h5 {
                            padding-top: 92px;
                            font-size: 34px;
                            line-height: 40px;
                        }
                        .info {
                            min-height: auto;
                            max-height: 170px;
                            overflow: hidden;
                            -webkit-line-clamp: 7;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                        }
                }
            }
        }
        .news_wrapper {
            .news_cont {
                @include pad-cont-style;

                .news_swiper_box {
                    height: 240px;

                    .new_cont_box {
                        .new_cont {
                            .new_h5 {
                                font-size: 20px;
                                line-height: 28px;
                            }
                        }
                    }
                }
            }
        }

    }

    //pad端
    @media only screen and (min-width:600px) and (max-width:992px) {
        .Performance_box{
            .right_box {
                .info_box {
                    padding-left: 3vw;
                    padding-right: 2vw;
                        .h5 {
                            padding-top: 84px;
                            font-size: 30px;
                            line-height: 38px;
                            padding-bottom: 18px
                        }
                        .info {
                            min-height: auto;
                            max-height: 170px;
                            overflow: hidden;
                            -webkit-line-clamp: 7;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                        }
                }
            }
        }
        .news_wrapper {
            .news_cont {
                @include pad-cont-style;

                .news_swiper_box {
                    height: 2.4rem;

                    .new_cont_box {
                        height: 100%;

                        .new_cont {
                            .new_h5 {
                                font-size: 0.22rem;
                                line-height: 0.3rem;
                            }

                            .new_p {
                                font-size: 0.14rem;
                                line-height: 0.21rem;
                            }
                        }
                    }
                }
            }
        }
    }

    // 手机端
    @media only screen and (max-width: 600px) {
        .Performance_box{
            .img_box {
                margin-top: 2.1333rem;
                height: 5.3333rem;
                width:calc(100% - .5333rem);
                box-sizing: border-box;
                overflow: hidden;
                background-repeat: no-repeat;
                background-position: top center;
                background-size:cover;
                position: relative;
                .phone_h5{
                    font-weight: 400;
                    font-size: 1.0667rem;
                    line-height: 1.28rem;
                    color: #FFFFFF;
                    position: absolute;
                    bottom: 0;
                    left: .5333rem;
                }
                .min_pc_phone_h5{
                    font-size: 48px;
                    line-height: 54px;
                }
            }
    
            .right_box {
                height: auto;
                width: calc(100% - 1.0667rem);
                box-sizing: border-box;
                margin: 0 auto;
                .info_box {
                    padding-left: 0;
                    .h5{
                        padding-top: 0;
                        font-weight: 400;
                        font-size: 1.0667rem;
                        line-height: 48px;
                        color: #18181B;
                    }
                    .info {
                        min-height: auto;
                        max-height: unset;
                        overflow: auto;
                        -webkit-line-clamp: unset;
                        -webkit-box-orient: unset;
                        display: block;
                    }
                }
            }
            //小屏幕 pc端
            .min_pc_right_box{
                width: calc(100% - 0.5333rem * 2);
               .info_box{
                .min_pc_h5{
                    font-size: 48px;
                }
               }
               
            }

        }

        .news_wrapper {
            height: 13.68rem;
            background-size: cover;
            background-position: top center;
            .news_cont {
                // @include phone—cont—style;
                width: 100%;
                display: block;
                margin-top: 2.1333rem;
                .news_swiper_box {
                    height: 10.5rem;
                    .h6{
                        left: .5333rem;
                    }
                    .new_cont_box {
                        display: block;
                        width: calc(100% - 1.0667rem);
                        padding: 0 .5333rem;

                        .new_cont {
                            width: 100%;
                            padding-top: 0.9rem;

                            .new_h5 {
                                font-size: 0.54rem;
                                line-height: 0.75rem;
                                color: #fff;
                                margin-bottom:0.21rem;
                            }
                        }

                        .new_img_box {
                            width: 100%;
                            margin-left: 0;
                            .new_img {
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .new_p {
                            margin-top: 0.3rem;
                            line-height: 20px;
                            word-break: break-all;
                            font-size: 0.38rem;
                            line-height: 0.65rem;
                            color: #fff;
                            font-weight: 300;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 4;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }

}
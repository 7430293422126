body {
  margin: 0;
  font-family:system, -apple-system, BlinkMacSystemFont,
 "PingFang SC", "Segoe UI", "Microsoft YaHei", "wenquanyi micro hei","Hiragino Sans GB", "Hiragino Sans GB W3", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
 "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  margin: 0;
  padding: 0;
}
html,body{
  width: 100%;
  height: 100%;
  /* -webkit-overflow-scrolling: auto; */
  overflow: hidden;
}
body{
  box-sizing: border-box;
	position: relative;
}
ul,li,ol{
  list-style: none;
}
a{
  text-decoration:none;color:#fff;
}
